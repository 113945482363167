<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-button to="/manage/organisation/invoices" variant="primary" class="float-right">
          Bekijk facturen
        </b-button>
        <h1 class="fs-4 font-weight-bold mb-4">Financieel</h1>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">
                    Omzet <small>(incl. btw)</small>
                </h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td></td>
                            <td class="text-right"></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Totaal</td>
                            <td class="text-right">0</td>
                        </tr>
                    </tfoot>
                </table>
            </b-card-body>
        </b-card>

        <b-card class="mb-3" no-body>
            <b-card-header>
                <h3 class="fs-5 mb-0">
                    Kosten <small>(excl. 21% btw)</small>
                </h3>
            </b-card-header>
            <b-card-body>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>Servicekosten</td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td>Transactiekosten</td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td>Terugstortingen</td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td><span class="font-weight-bolder">Totaal</span></td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td>Btw</td>
                            <td class="text-right">0</td>
                        </tr>
                        <tr>
                            <td><span class="font-weight-bolder">Totaal incl. btw</span></td>
                            <td class="text-right">0</td>
                        </tr>
                    </tbody>
                </table>
            </b-card-body>
        </b-card>

      </b-container>
    </div>
  </template>
  
  <script>
    export default {
        metaInfo: {
            title: 'Publiceer evenement',
        },
        components: {},
        data() {
            return {
                event: {},
                extraDetailsOptions: [
                    { text: 'Bedrijfsnaam', value: 'company_name' },
                    { text: 'Geboortedatum', value: 'birthdate' },
                    { text: 'Geslacht', value: 'gender' },
                    { text: 'Telefoonnummer', value: 'phone' },
                    { text: 'Adres', value: 'address' },
                    { text: 'Postcode', value: 'zipcode' },
                    { text: 'Woonplaats ', value: 'city ' },
                ],
                personalDetailRequiredOptions: [
                    { text: 'E-mail', value: 'email' },
                    { text: 'Telefoonnummer', value: 'phone' },
                    { text: 'Geboortedatum', value: 'birthdate' },
                    { text: 'Geslacht', value: 'gender' },
                ],
            }
        },
        computed: {},
        methods: {
            fetchEvent: function() {
                let id = this.$route.params.id;
                this.$axios.get("https://api.tixgo.nl/events/" + id)
                    .then( response => {
                        if (response.data) {
                            this.event = response.data;
                        }
                    }
                ).catch((reason) => {
                    console.log(reason);
                });
            },
            publishEvent: function() {
                this.$axios.put("https://api.tixgo.nl/events/" + this.$route.params.id + '/publish')
                    .then( response => {
                        response;
                        this.$router.push('/manage/events');
                    })
                ;
            },
            isSameDayEvent(event) {
                if (event.slots) {
                    let d1 = new Date(event.slots[0].start_at);
                    let d2 = new Date(event.slots[0].end_at);
                    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
                }
            },
        },
        created() {
            this.fetchEvent();
        },
    }
</script>